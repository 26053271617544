@charset "utf-8";

.FooterYear {
    text-align: center;
    /* background-color: lightgreen; */
}

.FooterCols {
    background-color: var(--footer-bg-color);
    color: var(--footer-color);
    justify-content: space-between;
}

.FooterCols h2 {
    margin: 0;
    padding: 0;
    color: var(--footer-color);
    font-size: 1.25em;
}

.FooterCols a {
    color: inherit;
}

.FooterCols .Col {
    text-align: left;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    margin: 0;
    min-width: 0;
    min-height: 0;
}

.FooterCols p {
    margin: 0;
    padding: 0;
}

.FooterCols address {
    margin: 0;
    margin-top: 1em;
    font-style: italic;
}

.ColContact {
    border-style: none;
    border-top: thin solid var(--background-color);
    /* border-bottom: thin solid var(--background-color); */
}

.ColDesc h2 {
    margin-bottom: 1em;
}

.ColContact h2 {
    margin-bottom: 0.5em;
}

@media screen and (min-width: 768px) {
    .ColContact {
        border-style: none;
        border-left: thin solid var(--background-color);
        /* border-right: thin solid var(--background-color); */
    }
}