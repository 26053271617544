@charset "utf-8";

:root {
	/* Global variables */
	--base-text-color: #959595;
	--top-color: #65BFDE;
	--article-heading-color: #6BBDE2;
	--htext-color: white;
	--footer-color: white;
	--footer-bg-color: #89c8e4;
	--background-color: white;
	--topnav-active-hover-color: white;
	--link-color: #49b6e9;
	--footer-hover-color: var(--link-color);
	--section-border-color: #dddddd;
	--marked-cell-color: #cccccc;
	--table-border-color: var(--base-text-color);

	/* kinda looks smoother */
	--footer-hover-color: var(--article-heading-color);
}

body {
	color: var(--base-text-color);
	font: normal 100%/1.8em Arial, Helvetica, sans-serif;
	background-color: var(--background-color);
}

a {
	color: var(--link-color);
	text-decoration: underline;
}

h1,
h2 {
	font-weight: bold;
}

.App {
	margin-top: 1em;
}

.Article h2 {
	color: var(--article-heading-color);
	margin: 1.25em 0;
	margin-bottom: 0.25em;
	padding: 0;
	font-weight: 900;
	font-size: 1.5em;
}

.Article section {
	margin-top: 2em;
}

.Article section:first-of-type {
	margin-top: 1em;
}

.Article p {
	padding: 0;
	margin: 0;
	margin-top: 1em;
}

.Article ul {
	margin: 0;
	padding: 0;
	padding-inline-start: 1.25em;
}

.Article .ListTitle {
	margin-bottom: 0.25em;
}

.Tablewrap {
	overflow: auto;
}

/* Styling table for Project page */

.SimpleTable {
	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;
	margin-top: 1em;
	min-width: 40em;
	max-width: 50em;
}

.SimpleTable td,
.SimpleTable th {
	border: thin solid var(--table-border-color);
	padding: 0.25em;
	overflow: hidden;
}

.SimpleTable th {
	text-align: center;
}

.SimpleTable tbody td {
	text-align: center;
}

.SimpleTable thead th:nth-child(1) {
	width: 60%;
}

.SimpleTable thead th:nth-child(2) {
	width: auto;
}

.SimpleTable thead th:nth-child(3) {
	width: auto;
}

.SimpleTable thead th:nth-child(4) {
	width: auto;
}

.SimpleTable thead th:nth-child(5) {
	width: auto;
}

.SimpleTable tbody tr td:nth-child(1) {
	text-align: left;
}

@media screen and (min-width: 768px) {
	.SimpleTable {
		min-width: auto;
	}
}

.LimitLineLen {
	max-width: 66em;
	margin-left: auto;
	margin-right: auto;
}

.BorderTop {
	border-top: thin solid var(--section-border-color);
}

.LogoImg {
	width: 7.5em;
	display: block;
	margin-left: 0;
	margin-right: auto;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

/* Styling table for Activities page */

.ReportTable {
	table-layout: auto;
	width: 100%;
	border-collapse: collapse;
	margin-top: 1em;

	min-width: 40em;
	max-width: 50em;
}

.ReportTable td,
.ReportTable th {
	border: thin solid var(--table-border-color);
	padding: 0.25em;
	overflow: hidden;
	text-align: center;
}

.ReportTable tbody td {
	text-align: center;
}

.ReportTable .TableLabel {
	text-align: left;
}

.MarkedCell {
	background-color: var(--marked-cell-color);
}

@media screen and (min-width: 768px) {
	.ReportTable {
		min-width: auto;
		max-width: none;
	}
}

.Nobr {
	white-space: nowrap;
}