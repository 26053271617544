@charset "utf-8";

/*
.Header {
    background-color: lightblue;
}
*/

.Header .Logo {
    padding-bottom: 0.5em;
    align-content: center;
}

.Header .MenuNav {
    padding-top: 0.5em;
    border-top: thin solid var(--section-border-color);
}

.Header .Logo h1 {
    padding: 0;
    margin: 0;
    color: var(--top-color);
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-size: 1.5em;
    font-weight: bold;
}

.NavItemLink {
    transition: none;
    transition: background-color .15s ease-in-out, color .2s ease-in-out;
    border-radius: 8px;
    text-align: center;
    padding: 0.2em 0.75em;
}

.NavItemLink:hover,
.NavItemLink.active {
    transition: none;
    transition: background-color .15s ease-in-out, color .2s ease-in-out;
    color: var(--topnav-active-hover-color);
    /* background: url(../../images/menu_a.gif) repeat-x center; */
    background-color: var(--top-color);
}

.Header .navbar {
    padding: 0;
}

.NavItem {
    padding: 0;
    padding-right: 0.5em;
    font-size: 1em;
}

.LogoAndNav {
    padding-bottom: 0.5em;
}

.HeaderImg img {
    display: block;
    width: 99%;
    max-width: 20em;
    margin: auto;
}

.HeaderImg {
    padding: 0.5em;
}

@media screen and (min-width: 768px) {
    .Header .Logo {
        padding-bottom: 0;
    }

    .Header .MenuNav {
        padding-top: 0;
        border-top: none;
    }
}