@charset "utf-8";

.Main {
    overflow: hidden;
    padding: 1em 0.25em;
    /* background-color: lightgreen; */
}

.Main table {
    margin-left: auto;
    margin-right: auto;
}